.about{
    height: 100vh;
   display: flex;
   align-items: center;
}
.a-left{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}
.a-right{
    flex:1;
}
.a-card.bg{
    position: absolute;
    top:50px;
    left:50px;
    background-color: #E9B824;
}
.a-card{
    width:60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;

}
.a-img{
    width:100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    overflow: hidden;
}
.a-title{
    color:#219C90;
}
.a-sub{
    margin: 20px 0px;
    
}
.a-desc{
    font-weight: 300;
   ;
}
@media screen and (max-width:480px){
    .about{
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
        overflow:hidden;
    }
    .a-left{
        width:50%;
    }
    .a-card{
        height: 10vh;
    }
    .a-card-bg{
        display:none;
    }
    .a-right{
        padding: 10px;
    }
}