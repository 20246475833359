.p{
    width:30%;
    height: 40vh;
    margin: 20px 10px;
    border: 2px solid #219C90;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    
}
.p-browser{
    height: 20px;
    background-color:#EE9322;
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 2;
}
.p-circle{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: white;
    margin: 3px;
}
.p-img{
    width:100%;
    transition:all 10s ease;
}
.p:hover .p-img{
    transform: translateY(-100%)
}
@media screen and (max-width:480px){
    .p{
    width:auto;
    height: fit-content;
    overflow: hidden;
}}