.c{
    height: 100vh;
    position: relative;
}
.c-bg{
    width:20px;
    height: 100%;
    background-color:#E9B824;
    position: absolute;
}
.c-wrapper{
    padding:50px;
    display: flex;
}
.c-left{
    flex:1;
    
}
.c-right{
    flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.c-title{
    font-size: 60px;
    width: 80%;
    color:#219C90;
}
.c-info-item{
    display: flex;
    align-items: center;
    margin:50px 0px;
    font-weight: 300;
    width: 70%;
}
.c-icon{
    width:30px;
height: 30px;
margin-right: 20px;}
.c-desc{
    font-weight: 200;
}
form{
margin-top: 20px;

}
input{
    width:50%;
    height: 50px;
    border:none;
    border-bottom: 1px solid black;
    margin:10px 0px;
    font-size: 14px;
    padding-left: 10px;
}
textarea{
    width:100%;
font-size: 14px;
padding-left: 10px;
}
button{
    border: none;
    padding: 15px;
    background-color:#EE9322;
    color: aliceblue;
    font-weight: 500;
    cursor: pointer;
}
@media screen and (max-width:480px){
    .c-wrapper{
        flex-direction: column;
    }
}